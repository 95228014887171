import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import AccountAuth from '../components/account/AccountAuth'
import SEO from '../components/shared/SEO'
import { colors, newDesktopVW } from '../styles'

const StyledAuthPage = styled.section`
  min-height: 100vh;
  display: block;
  color: ${colors.orange};
`

const AuthPage = ({
  data: {
    contentfulAuth: { seoTitle, slug: authSlug, seoDescription },
  },
}) => {
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} slug={authSlug} />
      <StyledAuthPage>
        <AccountAuth />
      </StyledAuthPage>
    </>
  )
}

export const PageQuery = graphql`
  query Auth($id: String!, $node_locale: String!) {
    contentfulAuth(id: { eq: $id }) {
      name
      slug
      seoTitle
      seoDescription
    }
    allContentfulAccountPage(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          slug
        }
      }
    }
  }
`

export default AuthPage
